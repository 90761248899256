// import React, { useState } from "react"
import React from "react"
import SEO from "../../../components/seo"
// import { Helmet } from "react-helmet"
import Layout from "../../../components/layout"
import TwoColumn from "../../../components/two-column"
// import CustomerJourney from '../../../components/customer-journey'
// import PlanActionInAction from "../../../components/plan-action-in-action"
import Preview from '../../../components/preview'
// import Popover from "../../../components/popover"
import ImageWithPopover from "../../../components/image-with-popover"
import { Link } from 'gatsby'

const bannerContent = {
  title: "Invoices and emails that reduce churn and retain customers",
  description: [
    {
      text: "Delivering the right message at the right time is essential to your subscription business. That's why with Billsby, you'll get access to the most advanced subscription invoices in the world, and a huge email library."
    }
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today',
      isExternal: true,
    },
    {
      scheduleButton: true,
      buttonColor: 'black'
    }
  ],
  sectionFeaturetag: true,
  svg: true,
  image: require('../../../images/invoices-and-emails.svg'),
  imageName: 'invoices-and-emails svg',
  backgroundColor: '#f3f5fb',
  textColor: '#000',
  imagePosition: 'right',
  featureTagButton: true,
  sectionInvoiceBanner: true,
  tags: [
    {
      class: 'adoric_invoices_advanced btn-white',
      text: 'Advanced Invoices',
      color: 'white'
    },
  ]
}

const invoicesContent = {
  title: "The biggest thing to happen to invoices since carbon paper",
  description: "Stop sending static PDF invoices to customers and start sending Billsby Advanced Invoices that reduce churn, deflect payment failures, provide pro-active customer service and upsell new products and services.",
  imageName: 'plan creation',
  image: 'invoice.png',
  popover: [
    {
      popoverContent: {
        title: "Downloadable invoices and credit notes",
        text: "For all those pesky accounting needs - yourself and your customers can download invoices and credit notes at the click of a button."
      }
    },
    {
      popoverContent: {
        title: "Instantly reattempt failed payments",
        text: "No need to login - customers can re-run failed payments on their invoices in one click."
      }
    },
    {
      popoverContent: {
        title: "Discount details",
        text: "Customers are reminded of the discounts that have been applied to their subscription and when they'll expire"
      }
    },
    {
      popoverContent: {
        title: "Prompt changes to payment details",
        text: "Reduced dunning rates - customers are always prompted to replace their card details when they fail to pay their invoices"
      }
    },
    {
      popoverContent: {
        title: "Just-in-time support",
        text: "Be there for your customers when they need you with the right contact details and information"
      }
    }
  ],
  brandingButton: true
}

const standardContent = { 
  title: "Consolidated invoicing included as standard",
  description: [
    {
      text: "If a customer has multiple plans up for renewal at the same, you don't want to send them multiple invoices or run the risk of declined charges by trying to capture payment form the same bank card multiple times. Instead, consolidate all to their charges intro one invoice."
    },
    {
      text: "It's so important that we include it as standard for all Billsby customers."
    }
  ],
  backgroundImage: require('../../../images/cash.png'),
  image: 'cash.png',
  imageName: 'cash',
  backgroundColor: '#243f5c',
  textColor: '#fff',
  imagePosition: 'full-right',
  backgroundPosition: 'right',
  sectionProtection: true,
  sectionStandard: true
}

const libraryContent = {
  title: "A huge library of subscription service emails at your disposal.",
  description: [
    {
      text: "We've got more than thirty emails configured to suit every stage and aspect of your customers subscription journey - including reminder emails, invoice emails, credit note emails and welcome emails. For each email you can change copy to suit your brand, and even advertise new products and services."
    },
    {
      text: "Or turn off our emails altogether and make your own bespoke communications with web hooks. The choice is yours."
    }
  ],
  sectionReport: true,
  sectionReport4: true,
  image: 'email-preview.png',
  imageName: 'dunning retention screenshot',
  backgroundImage: require('../../../images/email-preview.png'),
  backgroundColor: '#f3f5fb',
  textColor: '#000',
  imagePosition: 'full-right',
  // sectionReasonToStay: true
}

const EmailLayout = {
  title: "Customize every aspect of your communications",
  description:
    "Build trust, deflect questions and advertise and upsell new services with our notification emails - they're not just a way to send invoices, but also a great way to engage with customers who don't read your newsletter.",
  preview: (
    <>
      <div className="email-layout-holder">
        <div
          className="logo-holder"
          style={{
            // backgroundColor: `${colorLight ? colorLight + 60 : "#E3E3E3"}`,
            backgroundColor: '#E3E3E3'
          }}
        >
          {/* {logo ? <img src={logo} /> : <h2>Your Logo</h2>} */}
          <h2>Your Logo</h2>
        </div>
        <div className="content">
          <h3>Thank you for your payment</h3>
          <p>Hi [Customer First Name],</p>
          <p>
            We've successfully renewed your subscription and received your
            payment. Thank you. We hope you're enjoying your subscription.
          </p>
          <p className="text-bold">Your renewal</p>
          <ul>
            <li>
              <span>Payment date</span>
              <span>[DATE]</span>
            </li>
            <li>
              <span>Invoiced amount</span>
              <span>[Invoice amount]</span>
            </li>
            <li>
              <span>Payment method</span>
              <span>[Card type] ending XXXX</span>
            </li>
          </ul>
          <p className="link">View your invoice</p>
          <p>Regards,</p>
          <p>Your Comapany</p>
          <div className="banner">
            {/* {banner ? (
              <img src={banner} />
            ) : (
              <div className="placeholder"></div>
            )} */}
            <div className="placeholder"></div>
          </div>
          <p className="text-footer">
            If you've any questions about your subscription, don't hesitate to
            get in touch. Reply to this email or visit our{" "}
            <span className="link">support website</span>. We're here to help.
          </p>
        </div>

        <div className="arrow-holder">
          <div className="arrow-item">
            <p>Your logo and colours</p>
            <img src={require("../../../images/arrow-blue.svg")} alt="arrow-blue"/>
          </div>
          <div className="arrow-item">
            <p>Change copy to suit your brand</p>
            <img src={require("../../../images/arrow-orange.svg")} alt="arrow-orange"/>
          </div>
          <div className="arrow-item">
            <p>Space to advertise what's new</p>
            <img src={require("../../../images/arrow-blue.svg")} alt="arrow-blue"/>
          </div>
          <div className="arrow-item">
            <p>Direct customers to support</p>
            <img src={require("../../../images/arrow-orange-2.svg")} alt="arrow-orange"/>
          </div>
        </div>

        <div className="btn-brand-wrapper">
          <Link to="/product/creating/brand-identity" className="btn btn-black btn-brand"><img src={require('../../../images/magic-wand.svg')} className="white-magic-wand" alt="magic wand"/>See this with your branding</Link>
        </div>
      </div>
    </>
  ),
}

const InvoicesCreditnotesEmails = () => {
  return (
    <Layout className="brand-identity">
      <SEO 
        title="Consolidated Invoice, credit note, and email service | Billsby" 
        description="Subscription billing software with consolidated Invoices, emails and credit notes that deliver the right message at the right time | Billsby" 
        url="https://www.billsby.com/product/subscribers/invoices-creditnotes-emails"
      />

      <TwoColumn content={bannerContent}/>
      <ImageWithPopover content={invoicesContent} />
      <TwoColumn content={standardContent}/>
      <div className="section-credit-note">
        <div className="container">
          <div className="text-wrapper">
            <h2 className="title">We know what a credit note is, and we're not afraid to use it</h2>
            <p className="description">There's no such thing as a negative invoice - if you're refunding money to your customers, you need to issue them with credit notes. We automatically handle these for you, including tax reversal where necessary - helping to keep you on track.</p>

            <div className="image-wrapper">
              <img src={require('../../../images/credit-note@2x.png')} scrSet="credit-note@2x.png 2x" alt="billsby credit note"/>
            </div>

            <div className="button-wrapper">
              <Link to="/product/creating/brand-identity" className="btn btn-black btn-brand"><img src={require('../../../images/magic-wand.svg')} className="white-magic-wand" alt="magic wand"/>See this with your branding</Link>
            </div>
          </div>
        </div>
      </div>
      <TwoColumn content={libraryContent}/> 
      <Preview content={EmailLayout} className="email-layout-section section-preview-2" />
    </Layout>
  )
}

export default InvoicesCreditnotesEmails